import React from 'react'

const NotFound = () => {
  return (
    <div className="hksa-search-result">
        <p>لا توجد نتائج بحث</p>
    </div>
  )
}

export default NotFound